export const pageConfig = {
  'fuqing.aiassistant.aichan.info': {
    topBackgroundImage: "https://oss-open.aichan.info/imgs/H5/BG.png",
    isShowLovingMode: true
  },
  'fuqing.aiassistant.aishu.info': {
    topBackgroundImage: "https://oss-open.aichan.info/imgs/H5/BG.png",
    isShowLovingMode: true
  },
  'web.test.aichan.info': {
    topBackgroundImage: "https://oss-open.aichan.info/imgs/H5/BG.png",
    isShowLovingMode: true
  },
  'localhost:3000': {
    topBackgroundImage: "https://oss-open.aichan.info/imgs/H5/BG.png",
    isShowLovingMode: true
  }
} as any;