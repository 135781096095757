import React, { PureComponent } from 'react';
import { Spin, Tooltip,Input } from 'antd';
import { Modal } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import './pdf.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { Document, Page, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

class File extends PureComponent {

  state = {
    pageNumber: 1,
    pageNumberInput: 1,
    pageNumberFocus: false,
    numPages: 1,
    pageWidth: 300,
    fullscreen: false
  };

  onDocumentLoadSuccess = ({ numPages }: any ) => {
    this.setState({ numPages: numPages })
  }

  lastPage = () => {
    if (this.state.pageNumber == 1) {
      return
    }
    const page = this.state.pageNumber - 1
    this.setState({ pageNumber: page ,pageNumberInput:page})
  }
  nextPage = () => {
    if (this.state.pageNumber == this.state.numPages) {
      return
    }
    const page = this.state.pageNumber + 1
    this.setState({ pageNumber: page ,pageNumberInput:page})
  }
  onPageNumberFocus = (e: any) => {
    this.setState({ pageNumberFocus: true })
  };
  onPageNumberBlur = (e: any) => {
    this.setState({ pageNumberFocus: false ,pageNumberInput:this.state.pageNumber})
  };
  onPageNumberChange = (e: any) => {
    let value=e.target.value
    value=value<=0?1:value;
    value=value>=this.state.numPages?this.state.numPages:value;
    this.setState({ pageNumberInput: value })
  };
  toPage = (e: any) => {
    this.setState({ pageNumber: Number(e.target.value) })
  };

  pageZoomOut = () => {
    if (this.state.pageWidth <= 300) {
      return
    }
    const pageWidth = this.state.pageWidth * 0.8
    this.setState({ pageWidth: pageWidth })
  }
  pageZoomIn = () => {
    const pageWidth = this.state.pageWidth * 1.2
    this.setState({ pageWidth: pageWidth })
  }

  pageFullscreen = () => {
    if (this.state.fullscreen) {
      this.setState({ fullscreen: false, pageWidth: 600 })
    } else {
      this.setState({ fullscreen: true, pageWidth: window.screen.width - 40 })
    }
  }


  render() {
    const { pageNumber, pageNumberFocus, pageNumberInput,numPages, pageWidth, fullscreen } = this.state;
    const {
      filePath,
      showModal,
      onClose
    } = this.props as any

    // if (!showModal) {
    //   return null
    // }

    return (
      <Modal closable={true} okText="好的" cancelText={<></>} open={showModal} onOk={() => {
        onClose?.();
        this.setState({
          pageNumber: 1
        })
      }} onCancel={() => {
        onClose?.();
        this.setState({
          pageNumber: 1
        })
      }}>
      <div className='view'>
        <div className='pageContainer'>
          <Document
            file={filePath}
            onLoadSuccess={this.onDocumentLoadSuccess}
            loading={<Spin size="large" />}
          >
            <Page pageNumber={pageNumber} width={pageWidth} loading={<Spin size="large" />} />
          </Document>
        </div>

        <div className='pageTool'>
          <Tooltip title={pageNumber == 1 ? "已是第一页" : "上一页"}>
            <ArrowLeftOutlined  onClick={this.lastPage}/>
          </Tooltip>
          <Input style={{ width: '50px' }} width={50} value={pageNumberFocus ? pageNumberInput : pageNumber}
            onFocus={this.onPageNumberFocus}
            onBlur={this.onPageNumberBlur}
            onChange={this.onPageNumberChange}
            onPressEnter={this.toPage} type="number" /> / {numPages}
          <Tooltip title={pageNumber == numPages ? "已是最后一页" : "下一页"}>
            <ArrowRightOutlined onClick={this.nextPage}/>
          </Tooltip>
          <Tooltip title="放大">
            <ZoomInOutlined onClick={this.pageZoomIn}/>
          </Tooltip>
          <Tooltip title="缩小">
            <ZoomOutOutlined onClick={this.pageZoomOut}/>
          </Tooltip>
          {/* <Tooltip title={fullscreen ? "恢复默认" : '适合窗口'}>
            <Icon type={fullscreen ? "fullscreen-exit" : 'fullscreen'} onClick={this.pageFullscreen} />
            
          </Tooltip> */}
        </div>
      </div>
      </Modal>
    );
  }
}

export default (props: any) => {
  console.log(props.renderTime, '==renderTime==')
  return (<File {...props as any} />)
}