/* eslint-disable */
import * as React from 'react';
import { useContext, useEffect, useState, useMemo } from 'react';
import { Button, Card, Skeleton, Tabs, message } from 'antd';
import Frame from '../../utils/frame';
import FileList from '../../components/file/index';
import Block from '../../utils/block';
import logoPng from '../../assets/logo.png';
import titlePng from '../../assets/title.png';
import title1Png from '../../assets/title1.png';
import { postWebLogin } from '../../apis/account';
import accountManager from '../account/accountManager';
import { getHotQuestions, getHotFiles } from '../../apis/qa';
import { useNavigate } from 'react-router-dom';
import PDFViewer from '../../components/file/pdfViewer';
import { AccountModel } from '../../types/account';
import { TeamOutlined } from '@ant-design/icons';
import { AppContext } from '../../App';
import './index.css';
import { useSearchParams } from 'react-router-dom';
import AES from '../../utils/aes';
import { pageConfig } from './config';

interface Person {
  idcard: null;
  phone: null;
  userName: null;
}

export const IndexPage = () => {
  const { title, showLogo } = useContext(AppContext);
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [questions, setQuestions] = useState<string[]>([]);
  const [hotFiles, setHotFiles] = useState<{ name: string; url: string }[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [category2s, setCategory2s] = useState<string[]>([]);
  const [clickNum, setClickNum] = useState(0);
  const [isLoading, isLoadingSetter] = useState(true);
  const [errorMessage, errorMessageSetter] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isFor, setIsFor] = useState<string>('');
  const [userName, setUserName] = useState<string | null>('');
  const [phone, setPhone] = useState<string | null>('');
  const [idcard, setIdcard] = useState<string | null>('');
  const [hostUrl, setHostUrl] = useState('');
  const [isOpenCareMode, setIsOpenCareMode] = useState(false);
  // const [personInfo, setPersonInfo] = useState<Person>({
  //   idcard: null,
  //   phone: null,
  //   userName: null,
  // });

  const navigate = useNavigate();
  let setTimeoutNum: any;

  const [searchParams] = useSearchParams();

  const currentPageConfig = useMemo(() => {
    return pageConfig?.[window.location.host];
  }, [window.location.host]);

  // let a = JSON.parse(AES.decrypt(query));

  useEffect(() => {
    setHostUrl(window.location.host);
    console.log('url', window.location.host);
  }, []);

  useEffect(() => {
    if (searchParams.get('params')) {
      const query = searchParams.get('params');
      console.log(777, query);
      const a = JSON.parse(AES.decrypt(query));
      console.log(888, a);
      setUserName(a.userName);
      setPhone(a.phone);
      setIdcard(a.idcard);

      if (a.source && a.source == 'miniApps') {
        localStorage.setItem('source', a.source);
      } else {
        if (localStorage.getItem('source')) {
          localStorage.removeItem('source');
        }
      }

      // setPersonInfo(a);{
      if (query) {
        setIsFor(query);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    console.log(1111);

    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const handleTabletChange = (e: {
      matches: boolean | ((prevState: boolean) => boolean);
    }) => setIsMobile(e.matches);

    mediaQuery.addListener(handleTabletChange);
    

    return () => mediaQuery.removeListener(handleTabletChange);
  }, []);

  useEffect(() => {
    let account: AccountModel | null = accountManager.getAccount();
    accountManager.listenAccountChange((v) => (account = v));

    (async function () {
      if (account) {
        isLoadingSetter(false);
      } else {
        try {
          let sessionid;
          ({ sessionid, account } = await postWebLogin(
            window.location.host,
            '1234567890'
          ));
          // sessionid = '123123';
          // account = {
          //   nickname: 'Jason',
          //   username: 'Jason',
          //   avatar_url: '',
          //   sex: 'MALE',
          //   desc: '',
          //   is_staff: false,
          // };
          accountManager.setAccount(account, sessionid);
          isLoadingSetter(false);
        } catch (e) {
          // @ts-ignore
          errorMessageSetter('服务器连接失败，请稍后再试： ' + e?.message);
          return;
        }
      }
      const { questions, tags, category_2s } = await getHotQuestions();
      setCategory2s(category_2s);
      const { files } = await getHotFiles();
      setHotFiles(files);
      setQuestions(questions);
      setIsOpenCareMode(window.localStorage.getItem('isOpenCareMode') === 'true')
      setTimeout(() => {
        setPageSize(window.localStorage.getItem('isOpenCareMode') === 'true');
      }, 100);
      setTags(tags);
    })();

    // wx.downloadFile({
    //   // 示例 url，并非真实存在
    //   url: 'http://example.com/somefile.pdf',
    //   success: function (res) {
    //     const filePath = res.tempFilePath
    //     wx.openDocument({
    //       filePath: filePath,
    //       success: function (res) {
    //         console.log('打开文档成功')
    //       }
    //     })
    //   }
    // })
  }, []);
  const getUserInfoFunc = async (
    question: string = '',
    tag: string = '',
    category2 = ''
  ) => {
    navigate(
      `/qa/qa?question=${question}&tag=${tag}&category_2=${category2}&params=${encodeURIComponent(
        isFor
      )}`
    );
  };

  const handleChangeMode = (isOpenCareMode: boolean) => {
    isOpenCareMode = !isOpenCareMode;
    setIsOpenCareMode(isOpenCareMode);
    window.localStorage.setItem('isOpenCareMode', (isOpenCareMode).toString());
    setPageSize(isOpenCareMode);
  };

  const setPageSize = (isOpenCareMode: boolean) => {
    window.localStorage.setItem('isOpenCareMode', (isOpenCareMode).toString());
    const aaa = document.querySelector('.indexPage .ant-card .ant-card-body') as any;
    console.log(aaa, '===a===')
    if (!aaa) {
      return
    }
    for (let i = 0; i < aaa?.childNodes?.length; i++) {
      const item = aaa?.childNodes?.[i] as any;
      if (item) {
        item.style.fontSize = isOpenCareMode ? '18px' : '14px';
      }
    }
  }

  const onlineWorkFunc = async (question: string = '', tag: string = '') => {
    console.log(99988, isMobile);
    await navigate(`/onlineOffice?mobile=${isMobile}`);
  };

  const openDevByMultyClick = () => {
    setClickNum(clickNum + 1);
    console.log(clickNum, 9999);

    if (!setTimeoutNum) {
      setTimeoutNum = setTimeout(() => {
        setClickNum(0);
        clearTimeout(setTimeoutNum);
      }, 1000);
    }

    if (clickNum > 4) {
      // wx.navigateTo({
      //   url: '/pages/switchEnv/index',
      // });
    }
  };

  return (
    <Frame
      className='indexPage'
      grayBg
      style={{
        overflow: 'hidden',
        backgroundSize: '100% 100%',
        minHeight: '100vh',
      }}
    >

        {/* 是否展示关怀模式 */}
            {currentPageConfig?.isShowLovingMode ? (
        <div
          className='careName'
          onClick={async () => handleChangeMode(isOpenCareMode)}
        >
          <img
            src='https://oss-open.aichan.info/imgs/qa/carefor.png'
            className='careImg'
          />
          {isOpenCareMode ? (
            <span className='care-content'>关闭关怀模式</span>
          ) : (
            <span className='care-content'>打开关怀模式</span>
          )}
        </div>
      ) : null}

      {/* <iframe  ref="iframeRef" frameBorder="0" width="100%" src="https://oss-open.aichan.info/pdfs/%E6%AD%A6%E8%87%AA%E7%84%B6%E8%B5%84%E8%A7%84%E5%8F%91%5B2020%5D48%E5%8F%B7%20%E5%85%B3%E4%BA%8E%E5%81%9A%E5%A5%BD%E4%B8%8D%E5%8A%A8%E4%BA%A7%E7%99%BB%E8%AE%B0%E5%8A%9E%E7%90%86%E6%97%B6%E9%99%90%E2%80%9C%E5%86%8D%E6%8F%90%E9%80%9F%E2%80%9D%E7%9A%84%E9%80%9A%E7%9F%A5%EF%BC%88%E5%85%AC%E5%BC%80%EF%BC%89.pdf"/> */}
      {showPdfViewer ? (
        <PDFViewer
          onClose={() => setShowPdfViewer(false)}
          pdf_url='https://oss-open.aichan.info/pdfs/%E6%AD%A6%E8%87%AA%E7%84%B6%E8%B5%84%E8%A7%84%E5%8F%91%5B2020%5D48%E5%8F%B7%20%E5%85%B3%E4%BA%8E%E5%81%9A%E5%A5%BD%E4%B8%8D%E5%8A%A8%E4%BA%A7%E7%99%BB%E8%AE%B0%E5%8A%9E%E7%90%86%E6%97%B6%E9%99%90%E2%80%9C%E5%86%8D%E6%8F%90%E9%80%9F%E2%80%9D%E7%9A%84%E9%80%9A%E7%9F%A5%EF%BC%88%E5%85%AC%E5%BC%80%EF%BC%89.pdf'
        />
      ) : null}
      <div
        style={{
          position: 'relative',
          zIndex: 0,
          overflow: 'hidden',
          width: '100%',
          height: '244px',
          backgroundImage: `url(${currentPageConfig?.topBackgroundImage || 'https://oss-open.aichan.info/imgs/qa/BG%402x%20%281%29.png-compress'})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div
          style={{
            marginTop: 24 + 'px',
            fontSize: 18,
            textAlign: 'center',
            color: '#ffffff',
            lineHeight: 44 + 'px',
          }}
        >
          {title}
        </div>

        {!isMobile ? (
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
              textAlign: 'center',
              // marginLeft: '19rem',
              marginLeft: '7rem',
              // border: 'solid 1px red',
            }}
          >
            <div style={{ marginLeft: '20px', color: '#ffffff' }}>
              <div
                style={{
                  fontSize: '18px',
                  marginBottom: '12px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                Hi～，有什么可以帮您！
              </div>
              <div
                style={{ fontSize: '14px', textAlign: 'left' }}
                onClick={openDevByMultyClick}
              >
                {title}为您服务
              </div>
            </div>
            {currentPageConfig?.topBackgroundImage ? null : <img
              className='charerIconPc'
              src='https://oss-open.aichan.info/imgs/chareter.png'
            />}
            {/* <img
              className='charerIconPc'
              src='https://oss-open.aichan.info/imgs/H5/BG.png'
            /> */}
          </div>
        ) : (
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <div style={{ marginLeft: '20px', color: '#ffffff' }}>
              <div
                style={{
                  fontSize: '18px',
                  marginBottom: '12px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                Hi～，有什么可以帮您！
              </div>
              <div
                style={{ fontSize: '14px', textAlign: 'left' }}
                onClick={openDevByMultyClick}
              >
                {title}为您服务
              </div>
            </div>
            {currentPageConfig?.topBackgroundImage ? null : <img
              className='charerIcon'
              src='https://oss-open.aichan.info/imgs/chareter.png'
            />}
            {/* <img
              className='charerIcon'
              src='https://oss-open.aichan.info/imgs/H5/BG.png'
            /> */}
          </div>
        )}
      </div>

      {!isMobile ? (
        <div className='onlinePanalPC'>
          <div
            className='onlineQuestion'
            onClick={async () => getUserInfoFunc()}
          >
            <div className='onlineLeft'>
              <div className='content1'>在线咨询</div>
              <div className='content2'>快速解疑</div>
            </div>
            <div
              className='onlineLeftIcon'
              style={{
                backgroundImage: `linear-gradient(142deg, #61B5F4 0%, #1480FC 100%)`,
              }}
            >
              <img
                className='zixunIcon'
                src='https://oss-open.aichan.info/imgs/zixun.png'
              />
            </div>
          </div>

          <div className='onlineQuestion' onClick={() => onlineWorkFunc()}>
            <div className='onlineLeft'>
              <div className='content1'>常用功能</div>
              <div className='content2'>方便快捷</div>
            </div>
            <div
              className='onlineLeftIcon'
              style={{
                backgroundImage: `linear-gradient(142deg, #61B5F4 0%, #1480FC 100%)`,
              }}
            >
              <img
                className='banliIcon'
                src='https://oss-open.aichan.info/imgs/Vectort.png'
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='onlinePanal'>
          <div
            className='onlineQuestion'
            onClick={async () => getUserInfoFunc()}
          >
            <div className='onlineLeft'>
              <div className='content1'>在线咨询</div>
              <div className='content2'>快速解疑</div>
            </div>
            <div
              className='onlineLeftIcon'
              style={{
                backgroundImage: `linear-gradient(142deg, #61B5F4 0%, #1480FC 100%)`,
              }}
            >
              <img
                className='zixunIcon'
                src='https://oss-open.aichan.info/imgs/zixun.png'
              />
            </div>
          </div>

          <div className='onlineQuestion' onClick={() => onlineWorkFunc()}>
            <div className='onlineLeft'>
              <div className='content1'>常用功能</div>
              <div className='content2'>方便快捷</div>
            </div>
            <div
              className='onlineLeftIcon'
              style={{
                backgroundImage: `linear-gradient(142deg, #61B5F4 0%, #1480FC 100%)`,
              }}
            >
              <img
                className='banliIcon'
                src='https://oss-open.aichan.info/imgs/Vectort.png'
              />
            </div>
          </div>
        </div>
      )}

      {/* <div className="buttonsWrapper">
        <div className="indexButton" onClick={async() => getUserInfoFunc()}><img className="buttonImage" src="https://cdn.coffeebeats.cn/jj.png"/>在线咨询</div>
        <div className="indexButton" onClick={async () => {
          // await navigate(`/spot`)
        }}><img className="buttonImage" src="https://cdn.coffeebeats.cn/ll.png"/>办事网点</div>
      </div> */}

      {
        hostUrl === 'huangshi.aichan.info' ? <div className='huangshiMention'>办理不动产登记，可点击<a  onClick={() => {
          window.open('http://zwfw.hubei.gov.cn/');
        }}>湖北政务服务网</a></div> : null
      }

      {isLoading ? (
        <Block padding title={<>热门问题</>}>
          <Skeleton />
        </Block>
      ) : (
        <Block padding contentStyle={{ padding: '0' }}>
          {hostUrl !== 'huangshi.aichan.info' ? (
            <Tabs
              type='card'
              animated
              centered
              tabBarStyle={{ margin: 0, marginTop: '1.0rem' }}
              items={[
                {
                  label: `热门问题`,
                  key: 'question',

                  children: (
                    <Card bodyStyle={{ paddingTop: 10, paddingBottom: 10 }}>
                      {questions.slice(0, 6).map((q, i) => (
                        <div
                          key={i}
                          onClick={async () => getUserInfoFunc(q)}
                          style={{
                            padding: '12px 18px',
                            borderBottom: 'solid thin #f3f3f3',
                            textAlign: 'left',
                          }}
                        >
                          <img
                            src='https://cdn.coffeebeats.cn/nn.png'
                            style={{
                              width: '18px',
                              height: '15px',
                              marginRight: 5,
                              position: 'relative',
                              top: '2px',
                            }}
                          />
                          {q}
                        </div>
                      ))}
                    </Card>
                  ),
                },
                {
                  label: `热门标签`,
                  key: 'tag',
                  children: (
                    <Card bodyStyle={{ padding: '2%' }}>
                      {tags.slice(0, 9).map((t, i) => (
                        <Button
                          key={i}
                          onClick={async () => getUserInfoFunc('', t)}
                          style={{ width: '29.5%', margin: '1.5%' }}
                        >
                          {t}
                        </Button>
                      ))}
                    </Card>
                  ),
                },
                {
                  label: `问题类别`,
                  key: 'questionKind',
                  children: (
                    <Card>
                      {category2s.map((category2, i) => (
                        <Button
                          key={i}
                          style={{ width: '29.5%', margin: '1.5%' }}
                          onClick={async () =>
                            getUserInfoFunc('', '', category2)
                          }
                        >
                          {category2}
                        </Button>
                      ))}
                    </Card>
                  ),
                },
                {
                  label: `热点政策`,
                  key: 'policy',
                  children: (
                    <FileList
                      maxLength={5}
                      needImage={false}
                      files={hotFiles}
                    />
                  ),
                },
              ]}
            ></Tabs>
          ) : (
            <Tabs
              type='card'
              animated
              centered
              tabBarStyle={{ margin: 0, marginTop: '1.0rem' }}
              items={[
                {
                  label: `热门问题`,
                  key: 'question',

                  children: (
                    <Card bodyStyle={{ paddingTop: 10, paddingBottom: 10 }}>
                      {questions.slice(0, 6).map((q, i) => (
                        <div
                          key={i}
                          onClick={async () => getUserInfoFunc(q)}
                          style={{
                            padding: '12px 18px',
                            borderBottom: 'solid thin #f3f3f3',
                            textAlign: 'left',
                          }}
                        >
                          <img
                            src='https://cdn.coffeebeats.cn/nn.png'
                            style={{
                              width: '18px',
                              height: '15px',
                              marginRight: 5,
                              position: 'relative',
                              top: '2px',
                            }}
                          />
                          {q}
                        </div>
                      ))}
                    </Card>
                  ),
                },
                {
                  label: `热门标签`,
                  key: 'tag',
                  children: (
                    <Card bodyStyle={{ padding: '2%' }}>
                      {tags.slice(0, 9).map((t, i) => (
                        <Button
                          key={i}
                          onClick={async () => getUserInfoFunc('', t)}
                          style={{ width: '29.5%', margin: '1.5%' }}
                        >
                          {t}
                        </Button>
                      ))}
                    </Card>
                  ),
                },
                {
                  label: `问题类别`,
                  key: 'questionKind',
                  children: (
                    <Card>
                      {category2s.map((category2, i) => (
                        <Button
                          key={i}
                          style={{ width: '29.5%', margin: '1.5%' }}
                          onClick={async () =>
                            getUserInfoFunc('', '', category2)
                          }
                        >
                          {category2}
                        </Button>
                      ))}
                    </Card>
                  ),
                },
              ]}
            ></Tabs>
          )}
        </Block>
      )}

      {/* <Block contentStyle={{ width: '45.0rem', margin: '5.0rem 15.0rem' }}>
        <Button onClick={async() => getUserInfoFunc()} loading={isLoading} block size="large" type="primary" style={{ fontSize:"2.0rem", lineHeight: '2.0rem' }}>
          <TeamOutlined />
          在线咨询
        </Button>
        <span style={{ padding: '1.2rem', fontSize: '3.2rem', color: '#666' }}>
          {errorMessage}
        </span>
        <Button onClick={() => alert('敬请期待')} block size="large" type="default" style={{ fontSize:"2.0rem", lineHeight: '2.0rem', marginTop: '3.0rem' }}>
          更多服务
        </Button>
      </Block> */}

      {['测试', 'AI智能问答', '武汉不动产·AI能助'].includes(document.title) ? (
        <div className='footer'>
          <div>武汉市自然资源和城乡建设局主办</div>
          <div>武汉市不动产登记中心承办</div>
          <div>武汉市自然资源和规划信息中心提供技术支持</div>
        </div>
      ) : null}
    </Frame>
  );
};
