/* eslint-disable */
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Skeleton,
  Space,
  message as Message,
  Modal,
  message,
  Affix,
  Select,
} from 'antd';
// import { Cascader } from 'antd-mobile';

import Frame from '../../utils/frame';
import { unlockPage, lockPage } from '../../utils/lock';
import Recorder from 'recorder-core';
import 'recorder-core/src/engine/mp3';
import 'recorder-core/src/engine/mp3-engine';
import Block from '../../utils/block';
import {
  feedback,
  getHotQuestions,
  qa,
  recordUser,
  getHistoryQas,
  queryAreaList,
  checkReception,
} from '../../apis/qa';
import { getTencentInfo } from '../../apis/account';
// import robotPng from '../../assets/robot.png';
import userDefault from '../../assets/user_default.png';
import questionPng from '../../assets/question.png';
import accountManager from '../account/accountManager';
import useQuery from '../../utils/query';
import { AppContext } from '../../App';
import PageHeader from '../../utils/header/header';
import SpeechRecognizer from './speechRecognizer/index';
import WebAudioSpeechRecognizer from './webaudiospeechrecognizer';
import dayjs from 'dayjs';
import './qa.css';
import { DislikeOutlined, LikeOutlined, ToolOutlined } from '@ant-design/icons';
import AES from '../../utils/aes';

// 创建录音对象
const oRecorder = Recorder({
  type: 'mp3',
  bitRate: 16,
  sampleRate: 16000,
});

type ArryType = [
  {
    label: string;
    value: any;
  }
];
interface ArrayObject {
  label: string;
  value: string;
}

export const QaPage = () => {


  // const robotPng =
  //   'https://fangxt-object.oss-rg-china-mainland.aliyuncs.com/robot.png';

  // const title = '武汉不动产·AI能助'
  let account = accountManager.getAccount();

  const [questionText, questionTextSetter] = useState('');

  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [tencentInfo, setTencentInfo] = useState({} as any);
  const [audioInput, setAudioInput] = useState(false);

  const [guessCategory2Show, guessCategory2ShowSetter] = React.useState(false);
  const [chooseCategory2, chooseCategory2Setter] = React.useState('');
  const [category2s, setCategory2s] = useState<string[]>([]);
  const [category2Loading, category2LoadingSetter] = useState(true);

  const [guessTagShow, guessTagShowSetter] = React.useState(false);
  const [chooseTag, chooseTagSetter] = React.useState('');
  const [tagLoading, tagLoadingSetter] = useState(true);
  const [questions, setQuestions] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [visible, setVisible] = useState(false);
  // const [options, setOptions] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const [hostUrl, setHostUrl] = useState('');

  const [options, setOptions] = useState([
    {
      label: '',
      value: '',
    },
  ]);
  const [areaId, seAreaId] = useState<string>('');

  // const [optionsFather, setOptionsFather] = useState<ArryType>([
  //   {
  //     label: '',
  //     value: '',
  //     children: [],
  //   },
  // ]);

  const [qas, qasSetter] = React.useState<
    {
      type: 'ai' | 'user';
      record_id: string;
      time?: Date | undefined;
      message: string;
      collapse?: boolean;
      initCollapse?: boolean;
      useClicked?: boolean;
      correctionClicked?: boolean;
      noEffectClicked?: boolean;
      create_at?: string;
      answer_type?: string;
      related_questions?: any;
    }[]
  >([]);
  const [qaAnswerLoading, qaAnswerLoadingSetter] = React.useState(false);

  // 请求热门tags、及参数中tag或者问题的答案
  const { question, tag, category_2, params } = useQuery<{
    question: string;
    tag: string;
    category_2?: string;
    params?: string;
  }>();

  useEffect(() => {
    setHostUrl(window.location.host);
  }, []);
  const { title, showLogo } = useContext(AppContext);

  let robotPng: any
  if (hostUrl == 'fuqing.aiassistant.aichan.info') {
    robotPng = 'https://oss-open.aichan.info/imgs/qa/%E5%A4%B4%E5%83%8F%402x.png';
  } else {
    robotPng =
      'https://fangxt-object.oss-rg-china-mainland.aliyuncs.com/robot.png';
  }

  useEffect(() => {
    (async () => {
      // 开启录音进程
      // setTimeout(() => {
      //   oRecorder.open(() => {
      //     console.log('==oRecorder==')
      //   // 同意授权
      //   }, function (err: any) {
      //     // 拒绝授权
      //     console.log(err, 987)
      //   });
      // }, 3000)
      let infor;
      if (params) {
        infor = JSON.parse(AES.decrypt(params));
        console.log(infor);
        if (infor.source && infor.source == 'miniApps') {
          localStorage.setItem('source', infor.source);
        } else {
          if (localStorage.getItem('source')) {
            localStorage.removeItem('source');
          }
        }
      }

      const tencentInfo = await getTencentInfo('window.location.host');
      setTencentInfo(tencentInfo);
      // 开启录音进程
      setTimeout(() => {
        // oRecorder.open(() => {
        //   console.log('==oRecorder==')
        // // 同意授权
        // }, function (err: any) {
        //   // 拒绝授权
        //   console.log(err, 987)
        // });
      }, 3000);
      if (category_2) {
        requestRelatedQuestionByCategory2(category_2);
        return;
      }
      if (!(tag || infor?.tags?.[0]) && !question) {
        requestRelatedQuestionByTag('', true, '');
        return;
      }
      const { questions, tags } = await getHotQuestions();
      setQuestions(questions);
      setTags(tags);
      if (tag || infor?.tags?.[0]) {
        requestRelatedQuestion(
          tag || infor?.tags?.[0],
          Boolean(infor?.tags?.[0])
        );
        // requestRelatedQuestion(tag || '继承', Boolean(infor?.tags?.[0]));
      }
      setTimeout(() => {
        setPageSize(window.localStorage.getItem('isOpenCareMode') === 'true');
      }, 100);
      if (question) {
        requestQA(question);
      }
    })();
  }, [question, tag, params]);

  useEffect(() => {
    return () => {
      // 组件卸载时恢复滚动
      unlockPage();
    };
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)');
    const handleTabletChange = (e: {
      matches: boolean | ((prevState: boolean) => boolean);
    }) => setIsMobile(e.matches);

    mediaQuery.addListener(handleTabletChange);
    return () => mediaQuery.removeListener(handleTabletChange);
  }, []);

  const preventScroll = (event: { preventDefault: () => void }) => {
    event.preventDefault(); // 阻止滚动
  };

  const setPageSize = (isOpenCareMode: boolean) => {
    window.localStorage.setItem('isOpenCareMode', (isOpenCareMode).toString());
    const doms = document.querySelectorAll('.qaPage .ant-card .ant-card-body') as any;
    if (!doms?.length) {
      return
    }
    for (let i = 0; i < doms.length; i++) {
      const item = doms?.[i] as any;
      if (item) {
        item.style.fontSize = isOpenCareMode ? '18px' : '14px';
      }
    }
  }

  // 获取历史问题
  const onPullDownRefreshEvent = async () => {
    const { list: historyQas } = await getHistoryQas(
      qas.length > 0 ? qas[0].record_id : '',
    );
    qasSetter((oldQas) => {
      const newQas: typeof oldQas = [];
      for (let qa of historyQas) {
        newQas.push({
          type: 'user',
          record_id: qa.id,
          message: qa.question,
          related_questions: [],
          collapse: false,
          // initCollapse: false,
          time: new Date(qa.create_at),
          // time: dayjs(qa.create_at).format('YYYY-MM-DD HH:mm:ss')
        });
        newQas.push({
          type: 'ai',
          record_id: qa.id,
          message: qa.answer,
          answer_type: qa.answer_type,
          related_questions: [],
          collapse: qa.answer?.length < 75,
          // initCollapse: qa.answer.length > 75,
          time: new Date(qa.create_at),
          // time: dayjs(qa.create_at).format('YYYY-MM-DD HH:mm:ss')
        });
      }
      newQas.push(...oldQas);
      return newQas;
    });
    guessTagShowSetter(false);
    // guessCategory2ShowSetter(false)
  };
  const requestRelatedQuestionByTag = async (
    tag: string,
    showQuestions?: boolean,
    userType?: string
  ) => {
    questionTextSetter('');
    tagLoadingSetter(true);
    guessTagShowSetter(true);
    guessCategory2ShowSetter(false);
    chooseTagSetter(showQuestions ? '默认' : tag);
    const { questions, tags, category_2s } = await getHotQuestions(tag);
    setQuestions(questions);
    setTags(tags);
    setCategory2s(category_2s);
    tagLoadingSetter(false);
  };

  const requestRelatedQuestionByCategory2 = async (
    category_2: string,
    userType?: string
  ) => {
    questionTextSetter('');
    guessCategory2ShowSetter(true);
    category2LoadingSetter(true);
    guessTagShowSetter(false);
    chooseCategory2Setter(category_2);
    const { questions, tags, category_2s } = await getHotQuestions(
      '',
      category_2
    );
    setQuestions(questions);
    setTags(tags);
    setCategory2s(category_2s);
    category2LoadingSetter(false);
  };

  const requestRelatedQuestion = async (
    tag: string,
    noShowTagList?: boolean
  ) => {
    if (noShowTagList) {
      questionTextSetter('');
      const { questions, tags } = await getHotQuestions(tag);
      guessTagShowSetter(true);
      chooseTagSetter('默认');
      setQuestions(questions);
      tagLoadingSetter(false);
      setTags(tags);
      return;
    }
    questionTextSetter('');
    tagLoadingSetter(true);
    guessTagShowSetter(true);
    chooseTagSetter(tag);
    const { questions, tags } = await getHotQuestions(tag);
    setQuestions(questions);
    setTags(tags);
    tagLoadingSetter(false);
  };

  const handleAnswerContent = (data: string) => {
    return data;
    // return data.replace('人工客服', '<a target="_blank" href="https://www.w3school.com.cn">人工客服</a>')
  };
  const requestQA = async (question: string) => {
    // input失去焦点，键盘收起
    const inputDom = document.querySelector('.ant-input') as any;
    if (inputDom) {
      inputDom.blur();
    }
    questionTextSetter('');
    qasSetter((qas) => [
      ...qas,
      {
        type: 'user',
        recordId: '',
        record_id: '',
        message: question,
        time: new Date(),
      },
    ]);

    guessTagShowSetter(false);
    qaAnswerLoadingSetter(true);
    guessCategory2ShowSetter(false);
    try {
      const { answer, related_questions, record_id, answer_type } = await qa(
        question
      );

      if (params) {
        let a = JSON.parse(AES.decrypt(params));
        console.log(77, a);

        forTit(record_id, a.userName, a.idcard, a.phone);
      }

      qasSetter((qas) => [
        ...qas,
        {
          type: 'ai',
          answer_type,
          record_id: record_id,
          message: answer,
          related_questions,
          time: new Date(),
          collapse: answer?.length < 80,
        },
      ]);
    } catch (e) {
      qasSetter((qas) => [
        ...qas,
        {
          type: 'ai',
          record_id: '',
          message: '服务器连接失败，请稍后再试',
          time: new Date(),
        },
      ]);
    } finally {
      qaAnswerLoadingSetter(false);
    }
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
      setPageSize(window.localStorage.getItem('isOpenCareMode') === 'true');
    }, 0);
  };

  const forTit = async (
    id: string,
    userName: string,
    idCard: string,
    phone: string
  ) => {
    try {
      // let a =  JSON.parse(AES.decrypt(params)
      console.log(888, id, userName, idCard);
      const res = await recordUser(id, userName, idCard, phone);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const requestFeedback = async (
    qa: { type: 'ai' | 'user'; record_id: string; message: string; time: Date },
    attitude: string,
    message: string
  ) => {
    await feedback(qa.record_id, attitude, message);
    qasSetter((oldV) => {
      const newV = [...oldV];
      const item = newV.filter((v) => v.record_id === qa.record_id);
      if (item.length > 0) {
        item[0].record_id = '';
      }
      return newV;
    });
    Message.info('感谢您的反馈');
  };

  const feedbackInfo = (info: any) => {
    return () => {
      Modal.info({
        title: '纠错反馈',
        content: (
          <div>
            <Input.TextArea
              placeholder='请输入你的反馈'
              value={(window as any).feedbackMessage}
              onChange={(e) => {
                (window as any).feedbackMessage = e.target.value;
              }}
            ></Input.TextArea>
          </div>
        ),
        onOk() {
          if (!(window as any).feedbackMessage) {
            message.info('请输入内容');
            return;
          }
          requestFeedback(info, 'fix', (window as any).feedbackMessage);
        },
      });
    };
  };

  const handleServce = async () => {
    try {
      const { data } = await queryAreaList();

      console.log(data);
      const { CHILD } = data[0];
      console.log(999, CHILD);
      if (CHILD && CHILD.length > 0) {
        console.log(7777);
        let a: ArrayObject[] = [];
        CHILD.forEach((i: { AREAID: string; AREA_NAME: string }) => {
          a.push({
            label: i.AREA_NAME,
            value: i.AREAID,
          });
        });

        setOptions(a);
        // unlockPage();
        setVisible(true);

        console.log(99, a);

        // setOptions();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);

    seAreaId(value);
  };

  const secondLayerAi = (answer_type: string | undefined) => {
    if (!answer_type) {
      return false
    }

    if (answer_type === 'ai-gpt-4o-mini') {
      return false
    }
    return answer_type.indexOf('ai') >= 0;
  }

  const handleMyQuestion = () => {
    if (params) {
      const infor = JSON.parse(AES.decrypt(params));
      const obj = {
        userName: infor.userName,
        phone: infor.phone,
        idCard: infor.idcard,
        areaId: areaId,
        // gender: '女',
      };
      const value = AES.encrypt(JSON.stringify(obj));
      //https://test.stcir.com:31001/aimobile/#/MyQuestion测试环境
      //https://whrgkf.stcir.com/aimobile/#/MyQuestions生产环境
      let url = `https://whrgkf.stcir.com/aimobile/#/MyQuestion?params=${encodeURIComponent(
        value
      )}`;
      window.location.href = url;
    }
  };

  const handleAesTest = async () => {
    if (params) {
      const infor = JSON.parse(AES.decrypt(params));
      if (areaId == '') {
        Message.info('请选择您所在的区');
        return;
      }
      setVisible(false);
      try {
        const res = await checkReception(areaId);
        console.log(res);

        if (res.data == true) {
          const obj = {
            userName: infor.userName,
            phone: infor.phone,
            idCard: infor.idcard,
            areaId: areaId,
            // gender: '女',
          };

          const value = AES.encrypt(JSON.stringify(obj));
          //https://whrgkf.stcir.com/aimobile/#/ChatRoom 正式环境
          //https://test.stcir.com:32001/aimobile/#/ChatRoom 测试环境
          let url = `https://whrgkf.stcir.com/aimobile/#/ChatRoom?params=${encodeURIComponent(
            value
          )}`;
          window.location.href = url;
        } else {
          const obj = {
            userName: infor.userName,
            phone: infor.phone,
            idCard: infor.idcard,
            areaId: areaId,
            // gender: '女',
          };

          Modal.confirm({
            content: res.msg,
            okText: '确认',
            centered: true,
            onOk: async () => {
              const value = AES.encrypt(JSON.stringify(obj));
              //https://test.stcir.com:31001/aimobile/#/SuggestedIssues 测试环境
              //https://whrgkf.stcir.com/aimobile/#/SuggestedIssues生产环境
              let url = `https://whrgkf.stcir.com/aimobile/#/SuggestedIssues?params=${encodeURIComponent(
                value
              )}`;
              window.location.href = url;
              // setShowTextArea(false);
            },
            cancelText: '取消',
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    // let url =  https://test.stcir.com:31001/aimobile/#/ChatRoom?params=

    // const value = AES.encrypt(JSON.stringify(obj));
    // let url = `https://test.stcir.com:32001/aimobile/#/ChatRoom?params=${encodeURIComponent(
    //   value
    // )}`;
    // window.location.href = url;
    // console.log(999, url);

    // console.log(888, JSON.parse(AES.decrypt(value)));
  };

  console.log(
    guessCategory2Show,
    category2s,
    chooseTag,
    guessTagShow,
    '==category2s=='
  );

  return (
    <>
      {isMobile ? (
        <div className='qaWrapper'>
          <PageHeader title={title} />
          <div
            style={{
              position: 'fixed',
              width: '100%',
              zIndex: 0,
              height: '100vh',
              backgroundImage: 'url(https://cdn.coffeebeats.cn/nihao/BG.png)',
              backgroundSize: '100% 100%',
            }}
          ></div>

          <Frame
            className='qaPage'
            style={{
              overflowY: 'scroll',
              minHeight: '90vh',
              paddingBottom: '10rem',
              marginBottom: '10rem',
            }}
          >
            <Block style={{ paddingTop: '3.5rem', paddingBottom: 0 }}>
              <div
                className='historyIntro'
                onClick={() => {
                  onPullDownRefreshEvent();
                }}
                style={{
                  marginTop: '26px',
                  textAlign: 'center',
                  color: '#666',
                  position: 'relative',
                  zIndex: 100,
                }}
              >
                点击查看历史消息
              </div>
              <Row>
                <Col span={4}>
                  <img
                    src={robotPng}
                    style={{ width: '80%', height: 'auto' }}
                    alt='robot'
                  />
                </Col>
                <Col span={17}>
                  <div
                    style={{
                      padding: '2.0rem',
                      lineHeight: '3.0rem',
                      fontSize: '2.2rem',
                      textAlign: 'left',
                      color: '#666',
                    }}
                  >
                    <div style={{ marginBottom: '0.5rem', color: '#666' }}>
                      {title || '小Ai'}
                    </div>
                    <div>{startDate.toLocaleString()}</div>
                  </div>
                </Col>
                <Col span={3}>
                  {
                    <div className='ff'>
                      <div className='serviceBar'>
                        <span className='serviceIcon'></span>
                        <span
                          className='rightCornerText leftText'
                          onClick={() => {
                            handleServce();
                            lockPage();
                          }}
                        >
                          人工客服
                        </span>
                      </div>
                    </div>
                  }
                  {/*<img*/}
                  {/*  src={questionPng}*/}
                  {/*  onClick={() => guessTagShowSetter(true)}*/}
                  {/*  style={{ width: '80%', height: 'auto' }}*/}
                  {/*  alt='guess tag'*/}
                  {/*/>*/}
                </Col>
              </Row>
            </Block>
            <Block
              padding
              style={{
                padding: '0rem',
                paddingBottom: '1.0rem',
                textAlign: 'left',
              }}
            >
              <Card>
                <span
                  onClick={() => {
                    // oRecorder.start();
                  }}
                >
                  亲，我是您的在线助手，请问你想咨询什么问题?
                </span>
              </Card>
            </Block>

            {!guessTagShow &&
              qas.map((qa, index) => (
                <>
                  {qa.type === 'user' && (
                    <>
                      <Block
                        padding
                        style={{ paddingTop: '2.0rem', paddingBottom: 0 }}
                        key={index}
                      >
                        <Row>
                          <Col span={17} offset={3}>
                            <div
                              style={{
                                padding: '2.0rem',
                                lineHeight: '3.0rem',
                                fontSize: '2.5rem',
                                textAlign: 'right',
                                color: '#666',
                              }}
                            >
                              <div>{account.nickname || '我'}</div>
                              <div style={{ marginTop: '0.5rem' }}>
                                {new Date().toLocaleString()}
                              </div>
                            </div>
                          </Col>
                          <Col span={4}>
                            <img
                              src={account.avatar_url || userDefault}
                              style={{ width: '80%', height: 'auto' }}
                              alt='my avatar'
                            />
                          </Col>
                        </Row>
                      </Block>
                      <Block
                        className='gogogo'
                        style={{
                          padding: '1.0rem',
                          paddingBottom: '1.0rem',
                          display: 'flex',
                        }}
                      >
                        <Card
                          style={{ backgroundColor: '#5b83fd', color: 'white' }}
                        >
                          {qa.message}
                        </Card>
                      </Block>
                    </>
                  )}
                  {qa.type === 'ai' && (
                    <>
                      <Block
                        padding
                        style={{ padding: '2.0rem 1.0rem 0 1.0rem' }}
                        key={index}
                      >
                        <Row>
                          <Col span={4}>
                            <img
                              src={robotPng}
                              style={{ width: '80%', height: 'auto' }}
                              alt='robot avatar'
                            />
                          </Col>
                          <Col span={20}>
                            <div
                              style={{
                                padding: '2.0rem',
                                lineHeight: '3.0rem',
                                fontSize: '2.5rem',
                                textAlign: 'left',
                                color: '#666',
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: '0.5rem',
                                  color: '#666',
                                }}
                              >
                                {title || '小Ai'}
                              </div>
                              <div style={{ marginTop: '0.5rem' }}>
                                {new Date().toLocaleString()}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Block>
                      <Card
                        style={{
                          padding: '1.0rem',
                          paddingBottom: '1.0rem',
                          textAlign: 'left',
                          margin: '1.0rem 2.4rem',
                        }}
                        actions={
                          qa.record_id
                            ? [
                              <Space
                                key='good'
                                onClick={() =>
                                  requestFeedback(qa as any, 'good', '')
                                }
                              >
                                <LikeOutlined />
                                已解决问题
                              </Space>,
                              <Space
                                key='bad'
                                onClick={() =>
                                  requestFeedback(qa as any, 'bad', '')
                                }
                              >
                                <DislikeOutlined />
                                未解决问题
                              </Space>,
                              <Space key='fix' onClick={feedbackInfo(qa)}>
                                <ToolOutlined />
                                纠错
                              </Space>,
                            ]
                            : []
                        }
                      >
                        <div
                          className={
                            !qa?.collapse
                              ? 'contentWrapper'
                              : 'contentWrapperNo'
                          }
                        >
                          {qa.message.split('\n').map((v, i) => (
                            <div
                              key={i}
                              dangerouslySetInnerHTML={{
                                __html: handleAnswerContent(v),
                              }}
                            ></div>
                          ))}

                          {qa.answer_type === 'ai-gpt-4o-mini' ? <div style={{ color: '#999', fontSize: '12px', marginTop: '16px' }}>当前回答基于AI能力生成，仅供参考。</div> : null}
                          {secondLayerAi(qa.answer_type) ? <div style={{ color: '#999', fontSize: '12px', marginTop: '16px' }}>当前回答基于互联网资料生成，仅供参考。</div> : null}
                          <Card
                            title={
                              qa.answer_type === 'na' ||
                                qa.answer_type === 'recommend_qa_by_tag'
                                ? '您是否想问：'
                                : '您是否还想问：'
                            }
                          >
                            {qa.related_questions?.map((q: any, i: any) => (
                              <div
                                key={i}
                                style={{
                                  padding: '1.0rem',
                                  borderBottom: 'solid thin #ddd',
                                }}
                                onClick={async () => requestQA(q)}
                              >
                                <span
                                  style={{
                                    color:
                                      i <= 2
                                        ? 'red'
                                        : i <= 3
                                          ? 'orange'
                                          : 'gray',
                                  }}
                                >
                                  {i + 1}
                                </span>
                                . {q}
                              </div>
                            ))}
                          </Card>
                        </div>
                        {!qa?.collapse ? (
                          <div
                            className='collIcon'
                            onClick={() => {
                              const newQas = JSON.parse(JSON.stringify(qas));
                              newQas[index].collapse = !newQas[index].collapse;
                              qasSetter(newQas);
                              setTimeout(() => {
                                window.scrollTo(0, document.body.scrollHeight);
                              }, 100);
                            }}
                          >
                            展开<span className='collIconRight'>▽</span>
                          </div>
                        ) : null}
                      </Card>
                    </>
                  )}
                </>
              ))}

            {!guessTagShow && qaAnswerLoading && (
              <>
                <Block padding style={{ padding: '2.0rem 1.0rem 0 1.0rem' }}>
                  <Row>
                    <Col span={4}>
                      <img
                        src={robotPng}
                        style={{ width: '80%', height: 'auto' }}
                        alt='robot avatar'
                      />
                    </Col>
                    <Col span={20}>
                      <div
                        style={{
                          padding: '2.0rem',
                          lineHeight: '3.0rem',
                          fontSize: '2.5rem',
                          textAlign: 'left',
                          color: '#666',
                        }}
                      >
                        <div style={{ marginBottom: '0.5rem', color: '#666' }}>
                          {title || '小Ai'}
                        </div>
                        <div style={{ marginTop: '0.5rem' }}>
                          {new Date().toLocaleString()}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Block>
                <Card
                  style={{
                    padding: '1.0rem',
                    paddingBottom: '1.0rem',
                    textAlign: 'left',
                    margin: '1.0rem 2.4rem',
                  }}
                >
                  <div className='contentWrapperNo'>问题接收中……</div>
                </Card>
              </>
            )}

            {guessCategory2Show && (
              <Block padding>
                <Card title='猜你想问'>
                  {category2s.map((q, i) => (
                    <Button
                      key={i}
                      onClick={async () =>
                        await requestRelatedQuestionByCategory2(q, '')
                      }
                      style={{
                        width: '29.5%',
                        margin: '1.5%',
                        color:
                          chooseCategory2 === q
                            ? '#4096ff'
                            : 'rgba(0, 0, 0, 0.88)',
                        borderColor:
                          chooseCategory2 === q ? '#4096ff' : '#d9d9d9',
                      }}
                    >
                      {q}
                    </Button>
                  ))}
                </Card>
              </Block>
            )}
            {guessCategory2Show &&
              chooseCategory2 &&
              (category2Loading ? (
                <Block padding>
                  <Skeleton />
                </Block>
              ) : (
                <Block padding>
                  <Card title='您是否想问：'>
                    {questions.slice(0, 6).map((q, i) => (
                      <div
                        key={i}
                        style={{
                          padding: '5px',
                          borderBottom: '1rpx solid #eee',
                          fontSize: `12px`,
                        }}
                        onClick={async () => requestQA(q)}
                      >
                        <span
                          style={{
                            color: i <= 2 ? 'red' : i <= 3 ? 'orange' : 'gray',
                          }}
                        >
                          {i + 1}
                        </span>
                        . {q}
                      </div>
                    ))}
                  </Card>
                </Block>
              ))}
            {chooseTag !== '默认' && guessTagShow && (
              <Block
                padding
                contentStyle={{ padding: '0 1.0rem', textAlign: 'left' }}
              >
                <Card title='您是否想问：' bodyStyle={{ padding: '2%' }}>
                  {tags.slice(0, 9).map((q, i) => (
                    <Button
                      key={i}
                      type={chooseTag === q ? 'primary' : 'default'}
                      onClick={async () => await requestRelatedQuestion(q)}
                      style={{ width: '29.5%', margin: '1.5%' }}
                    >
                      {q}
                    </Button>
                  ))}
                </Card>
              </Block>
            )}
            {guessTagShow &&
              chooseTag &&
              (tagLoading ? (
                <Block padding>
                  <Skeleton />
                </Block>
              ) : (
                <Block
                  padding
                  contentStyle={{ padding: '0 1.0rem', marginTop: '1.0rem' }}
                >
                  <Card bodyStyle={{ paddingTop: 10, paddingBottom: 0 }}>
                    {questions.slice(0, 6).map((q, i) => (
                      <div
                        key={i}
                        style={{
                          padding: '1.0rem',
                          borderBottom: 'solid thin #ddd',
                        }}
                        onClick={async () => requestQA(q)}
                      >
                        <span
                          style={{
                            color: i <= 2 ? 'red' : i <= 3 ? 'orange' : 'gray',
                          }}
                        >
                          {i + 1}
                        </span>
                        . {q}
                      </div>
                    ))}
                  </Card>
                </Block>
              ))}

            <Block
              padding
              contentStyle={{
                padding: '0',
                marginTop: '1.0rem',
                position: 'fixed',
                width: '100%',
                bottom: 0,
              }}
            >
              <Card>
                <SpeechRecognizer
                  tencentInfo={tencentInfo}
                  onFinish={(v) => {
                    console.log(v, '====v====');
                    questionTextSetter(v);
                  }}
                />
                <Input.Search
                  placeholder='请输入你想问的问题'
                  enterButton='确认'
                  value={questionText}
                  onChange={(e) => questionTextSetter(e.target.value)}
                  size='large'
                  style={{
                    width: '90%',
                    marginLeft: '9%',
                    backgroundColor: '#fff',
                  }}
                  onSearch={async () => requestQA(questionText)}
                />
              </Card>
            </Block>
            {/*
        <Cascader
          options={options}
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
        /> */}

            <Modal
              title='请选择您所在区'
              centered
              // style={{ top: 20 }}
              open={visible}
              okText='确定'
              cancelText='取消'
              onOk={() => {
                unlockPage();
                handleAesTest();
              }}
              afterClose={() => {
                unlockPage();
              }}
              onCancel={() => {
                setVisible(false);
                unlockPage();
              }}
            >
              <Select
                // showSearch
                style={{ width: 200 }}
                placeholder='请选择您所在区'
                optionFilterProp='label'
                // filterOption={(input, option) =>
                //   (option?.label ?? '').includes(input)
                // }
                // filterSort={(optionA, optionB) =>
                //   (optionA?.label ?? '')
                //     .toLowerCase()
                //     .localeCompare((optionB?.label ?? '').toLowerCase())
                // }
                options={options}
                onChange={handleChange}
              />

              {/* <Select
            mode='tags'
            style={{ width: '100%' }}
            placeholder='Tags Mode'
            // onChange={handleChange}
            options={options}
          /> */}
            </Modal>
          </Frame>
        </div>
      ) : (
        <div className='qaWrapper'>
          <PageHeader title={title} />
          <div
            style={{
              position: 'fixed',
              width: '100%',
              zIndex: 0,
              height: '100vh',
              backgroundImage: 'url(https://cdn.coffeebeats.cn/nihao/BG.png)',
              backgroundSize: '100% 100%',
              maxWidth: '750px',
              margin: '0 auto',
            }}
          ></div>

          <Frame
            className='qaPage'
            style={{
              overflowY: 'scroll',
              minHeight: '90vh',
              paddingBottom: '10rem',
              marginBottom: '10rem',
            }}
          >
            <Block style={{ paddingTop: '3.5rem', paddingBottom: 0 }}>
              <div
                className='historyIntro'
                onClick={() => {
                  onPullDownRefreshEvent();
                }}
                style={{
                  marginTop: '26px',
                  textAlign: 'center',
                  color: '#666',
                  position: 'relative',
                  zIndex: 100,
                }}
              >
                点击查看历史消息
              </div>
              <Row>
                <Col span={4}>
                  <img
                    src={robotPng}
                    style={{ width: '80%', height: 'auto' }}
                    alt='robot'
                  />
                </Col>
                <Col span={17}>
                  <div
                    style={{
                      padding: '2.0rem',
                      lineHeight: '3.0rem',
                      fontSize: '2.2rem',
                      textAlign: 'left',
                      color: '#666',
                    }}
                  >
                    <div style={{ marginBottom: '0.5rem', color: '#666' }}>
                      {title || '小Ai'}
                    </div>
                    <div>{startDate.toLocaleString()}</div>
                  </div>
                </Col>
                {hostUrl == 'wuhan.aiassistant.aichan.info' ? (
                  <Col span={3}>
                    <div className='rightCornerBarFullPCWrapper'>
                      <div className='rightCornerBarFullPCWrapperInner'>
                        <div className='rightCornerBarFullPC'>
                          <div className='serviceBar'>
                            <span className='serviceIcon'></span>
                            <span
                              className='rightCornerText leftText'
                              onClick={() => {
                                handleServce();
                                lockPage();
                              }}
                            >
                              人工客服
                            </span>
                          </div>
                        </div>
                        <div className='rightCornerBarFullPC1'>
                          <div className='serviceBar'>
                            <span className='serviceIcon1'></span>
                            <span
                              className='rightCornerText leftText'
                              onClick={() => {
                                handleMyQuestion();
                                lockPage();
                              }}
                            >
                              我的留言
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*<img*/}
                    {/*  src={questionPng}*/}
                    {/*  onClick={() => guessTagShowSetter(true)}*/}
                    {/*  style={{ width: '80%', height: 'auto' }}*/}
                    {/*  alt='guess tag'*/}
                    {/*/>*/}
                  </Col>
                ) : null}
              </Row>
            </Block>
            <Block
              padding
              style={{
                padding: '0rem',
                paddingBottom: '1.0rem',
                textAlign: 'left',
              }}
            >
              <Card>
                <span
                  onClick={() => {
                    // oRecorder.start();
                  }}
                >
                  亲，我是您的在线助手，请问你想咨询什么问题?
                </span>
              </Card>
            </Block>

            {!guessTagShow &&
              qas.map((qa, index) => (
                <>
                  {qa.type === 'user' && (
                    <>
                      <Block
                        padding
                        style={{ paddingTop: '2.0rem', paddingBottom: 0 }}
                        key={index}
                      >
                        <Row>
                          <Col span={17} offset={3}>
                            <div
                              style={{
                                padding: '2.0rem',
                                lineHeight: '3.0rem',
                                fontSize: '2.5rem',
                                textAlign: 'right',
                                color: '#666',
                              }}
                            >
                              <div>{account.nickname || '我'}</div>
                              <div style={{ marginTop: '0.5rem' }}>
                                {new Date().toLocaleString()}
                              </div>
                            </div>
                          </Col>
                          <Col span={4}>
                            <img
                              src={account.avatar_url || userDefault}
                              style={{ width: '80%', height: 'auto' }}
                              alt='my avatar'
                            />
                          </Col>
                        </Row>
                      </Block>
                      <Block
                        className='gogogo'
                        style={{
                          padding: '1.0rem',
                          paddingBottom: '1.0rem',
                          display: 'flex',
                        }}
                      >
                        <Card
                          style={{ backgroundColor: '#5b83fd', color: 'white' }}
                        >
                          {qa.message}
                        </Card>
                      </Block>
                    </>
                  )}
                  {qa.type === 'ai' && (
                    <>
                      <Block
                        padding
                        style={{ padding: '2.0rem 1.0rem 0 1.0rem' }}
                        key={index}
                      >
                        <Row>
                          <Col span={4}>
                            <img
                              src={robotPng}
                              style={{ width: '80%', height: 'auto' }}
                              alt='robot avatar'
                            />
                          </Col>
                          <Col span={20}>
                            <div
                              style={{
                                padding: '2.0rem',
                                lineHeight: '3.0rem',
                                fontSize: '2.5rem',
                                textAlign: 'left',
                                color: '#666',
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: '0.5rem',
                                  color: '#666',
                                }}
                              >
                                {title || '小Ai'}
                              </div>
                              <div style={{ marginTop: '0.5rem' }}>
                                {new Date().toLocaleString()}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Block>
                      <Card
                        style={{
                          padding: '1.0rem',
                          paddingBottom: '1.0rem',
                          textAlign: 'left',
                          margin: '1.0rem 2.4rem',
                        }}
                        actions={
                          qa.record_id
                            ? [
                              <Space
                                key='good'
                                onClick={() =>
                                  requestFeedback(qa as any, 'good', '')
                                }
                              >
                                <LikeOutlined />
                                已解决问题
                              </Space>,
                              <Space
                                key='bad'
                                onClick={() =>
                                  requestFeedback(qa as any, 'bad', '')
                                }
                              >
                                <DislikeOutlined />
                                未解决问题
                              </Space>,
                              <Space key='fix' onClick={feedbackInfo(qa)}>
                                <ToolOutlined />
                                纠错
                              </Space>,
                            ]
                            : []
                        }
                      >
                        <div
                          className={
                            !qa?.collapse
                              ? 'contentWrapper'
                              : 'contentWrapperNo'
                          }
                        >
                          {qa.message.split('\n').map((v, i) => (
                            <div
                              key={i}
                              dangerouslySetInnerHTML={{
                                __html: handleAnswerContent(v),
                              }}
                            ></div>
                          ))}
                          {qa.answer_type === 'ai-gpt-4o-mini' ? <div style={{ color: '#999', fontSize: '12px', marginTop: '16px' }}>当前回答基于AI能力生成，仅供参考。</div> : null}
                          {secondLayerAi(qa.answer_type) ? <div style={{ color: '#999', fontSize: '12px', marginTop: '16px' }}>当前回答基于互联网资料生成，仅供参考。</div> : null}
                          {/* {qa.answer_type === 'na' ? <div style={{ color: '#999', fontSize: '12px', marginTop: '16px' }}>您也可以通过本页面人工客服功能，或提问武汉市及各区的咨询电话获取帮助。</div> : null} */}
                          <Card
                            title={
                              qa.answer_type === 'na' ||
                                qa.answer_type === 'recommend_qa_by_tag'
                                ? '您是否想问：'
                                : '您是否还想问：'
                            }
                          >
                            {qa.related_questions?.map((q: any, i: any) => (
                              <div
                                key={i}
                                style={{
                                  padding: '1.0rem',
                                  borderBottom: 'solid thin #ddd',
                                }}
                                onClick={async () => requestQA(q)}
                              >
                                <span
                                  style={{
                                    color:
                                      i <= 2
                                        ? 'red'
                                        : i <= 3
                                          ? 'orange'
                                          : 'gray',
                                  }}
                                >
                                  {i + 1}
                                </span>
                                . {q}
                              </div>
                            ))}
                          </Card>
                        </div>
                        {!qa?.collapse ? (
                          <div
                            className='collIcon'
                            onClick={() => {
                              const newQas = JSON.parse(JSON.stringify(qas));
                              newQas[index].collapse = !newQas[index].collapse;
                              qasSetter(newQas);
                              setTimeout(() => {
                                window.scrollTo(0, document.body.scrollHeight);
                              }, 100);
                            }}
                          >
                            展开<span className='collIconRight'>▽</span>
                          </div>
                        ) : null}
                      </Card>
                    </>
                  )}
                </>
              ))}

            {!guessTagShow && qaAnswerLoading && (
              <>
                <Block padding style={{ padding: '2.0rem 1.0rem 0 1.0rem' }}>
                  <Row>
                    <Col span={4}>
                      <img
                        src={robotPng}
                        style={{ width: '80%', height: 'auto' }}
                        alt='robot avatar'
                      />
                    </Col>
                    <Col span={20}>
                      <div
                        style={{
                          padding: '2.0rem',
                          lineHeight: '3.0rem',
                          fontSize: '2.5rem',
                          textAlign: 'left',
                          color: '#666',
                        }}
                      >
                        <div style={{ marginBottom: '0.5rem', color: '#666' }}>
                          {title || '小Ai'}
                        </div>
                        <div style={{ marginTop: '0.5rem' }}>
                          {new Date().toLocaleString()}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Block>
                <Card
                  style={{
                    padding: '1.0rem',
                    paddingBottom: '1.0rem',
                    textAlign: 'left',
                    margin: '1.0rem 2.4rem',
                  }}
                >
                  <div className='contentWrapperNo'>问题接收中……</div>
                </Card>
              </>
            )}

            {guessCategory2Show && (
              <Block padding>
                <Card title='猜你想问'>
                  {category2s.map((q, i) => (
                    <Button
                      key={i}
                      onClick={async () =>
                        await requestRelatedQuestionByCategory2(q, '')
                      }
                      style={{
                        width: '29.5%',
                        margin: '1.5%',
                        color:
                          chooseCategory2 === q
                            ? '#4096ff'
                            : 'rgba(0, 0, 0, 0.88)',
                        borderColor:
                          chooseCategory2 === q ? '#4096ff' : '#d9d9d9',
                      }}
                    >
                      {q}
                    </Button>
                  ))}
                </Card>
              </Block>
            )}
            {guessCategory2Show &&
              chooseCategory2 &&
              (category2Loading ? (
                <Block padding>
                  <Skeleton />
                </Block>
              ) : (
                <Block padding>
                  <Card title='您是否想问：'>
                    {questions.slice(0, 6).map((q, i) => (
                      <div
                        key={i}
                        style={{
                          padding: '5px',
                          borderBottom: '1rpx solid #eee',
                          fontSize: `12px`,
                        }}
                        onClick={async () => requestQA(q)}
                      >
                        <span
                          style={{
                            color: i <= 2 ? 'red' : i <= 3 ? 'orange' : 'gray',
                          }}
                        >
                          {i + 1}
                        </span>
                        . {q}
                      </div>
                    ))}
                  </Card>
                </Block>
              ))}
            {chooseTag !== '默认' && guessTagShow && (
              <Block
                padding
                contentStyle={{ padding: '0 1.0rem', textAlign: 'left' }}
              >
                <Card title='您是否想问：' bodyStyle={{ padding: '2%' }}>
                  {tags.slice(0, 9).map((q, i) => (
                    <Button
                      key={i}
                      type={chooseTag === q ? 'primary' : 'default'}
                      onClick={async () => await requestRelatedQuestion(q)}
                      style={{ width: '29.5%', margin: '1.5%' }}
                    >
                      {q}
                    </Button>
                  ))}
                </Card>
              </Block>
            )}
            {guessTagShow &&
              chooseTag &&
              (tagLoading ? (
                <Block padding>
                  <Skeleton />
                </Block>
              ) : (
                <Block
                  padding
                  contentStyle={{ padding: '0 1.0rem', marginTop: '1.0rem' }}
                >
                  <Card bodyStyle={{ paddingTop: 10, paddingBottom: 0 }}>
                    {questions.slice(0, 6).map((q, i) => (
                      <div
                        key={i}
                        style={{
                          padding: '1.0rem',
                          borderBottom: 'solid thin #ddd',
                        }}
                        onClick={async () => requestQA(q)}
                      >
                        <span
                          style={{
                            color: i <= 2 ? 'red' : i <= 3 ? 'orange' : 'gray',
                          }}
                        >
                          {i + 1}
                        </span>
                        . {q}
                      </div>
                    ))}
                  </Card>
                </Block>
              ))}

            <Block
              padding
              contentStyle={{
                padding: '0',
                marginTop: '1.0rem',
                position: 'fixed',
                width: '100%',
                bottom: 0,
                maxWidth: '750px',
              }}
            >
              <Card>
                <SpeechRecognizer
                  tencentInfo={tencentInfo}
                  onFinish={(v) => {
                    console.log(v, '====v====');
                    questionTextSetter(v);
                  }}
                />
                <Input.Search
                  placeholder='请输入你想问的问题'
                  enterButton='确认'
                  value={questionText}
                  onChange={(e) => questionTextSetter(e.target.value)}
                  size='large'
                  style={{
                    width: '90%',
                    marginLeft: '9%',
                    backgroundColor: '#fff',
                  }}
                  onSearch={async () => requestQA(questionText)}
                />
              </Card>
            </Block>
            {/*
    <Cascader
      options={options}
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
    /> */}

            <Modal
              title='请选择您所在区'
              style={{ top: 180 }}
              open={visible}
              okText='确定'
              cancelText='取消'
              onOk={() => {
                handleAesTest();
                unlockPage();
              }}
              afterClose={() => {
                unlockPage();
              }}
              onCancel={() => {
                setVisible(false);
                unlockPage();
              }}
            >
              <Select
                // showSearch
                style={{ width: 200 }}
                placeholder='请选择您所在区'
                optionFilterProp='label'
                dropdownStyle={{ maxHeight: '300px' }}
                // filterOption={(input, option) =>
                //   (option?.label ?? '').includes(input)
                // }
                // filterSort={(optionA, optionB) =>
                //   (optionA?.label ?? '')
                //     .toLowerCase()
                //     .localeCompare((optionB?.label ?? '').toLowerCase())
                // }
                options={options}
                onChange={handleChange}
              />

              {/* <Select
        mode='tags'
        style={{ width: '100%' }}
        placeholder='Tags Mode'
        // onChange={handleChange}
        options={options}
      /> */}
            </Modal>
          </Frame>
        </div>
      )}
    </>
  );
};
